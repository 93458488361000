import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import MetaInfo from 'vue-meta-info'
import PageTemplate from '@/components/PageTemplate'
import 'amfe-flexible';
import store from './utils/store'

import { message } from 'ant-design-vue';
import 'ant-design-vue/lib/message/style/css';
message.config({ maxCount: 1 })

// 按需引入ant-design
import Button from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/button/style/css';

import Modal from 'ant-design-vue/lib/modal';
import 'ant-design-vue/lib/modal/style/css';

import Form from 'ant-design-vue/lib/form';
import 'ant-design-vue/lib/form/style/css';

import Input from 'ant-design-vue/lib/input';
import 'ant-design-vue/lib/input/style/css';

import Select from 'ant-design-vue/lib/select';
import 'ant-design-vue/lib/select/style/css';

import FormModel from 'ant-design-vue/lib/form-model';
import 'ant-design-vue/lib/form-model/style/css';

Vue.config.productionTip = false
Vue.use(MetaInfo).use(Button).use(Modal).use(Form).use(Input).use(Select).use(FormModel)
Vue.component('page-template', PageTemplate)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
