<template>
  <div id="app">
    <page-template>
      <router-view></router-view>
    </page-template>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      isPC: this.$store.state.isPC
    }
  },
  mounted () {
    const that = this
    that.isPC = document.body.clientWidth > 980
    this.$store.commit('increment', that.isPC)
    window.onresize = () => {
      return (() => {
        that.isPC = document.body.clientWidth > 980
        this.$store.commit('increment', that.isPC)
        setrem()
      })()
    }

    // 屏幕适配处理（该页面只触发onresize）
    function setrem() {
      var windoww = document.body.clientWidth + 17
      if (windoww > 981) { // pc端时处理根节点字体大小
        getRem(1920, 50)
      }
    }

    function getRem(pwidth, prem) {
      var html = document.getElementsByTagName("html")[0];
      var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
      var foneSize = oWidth / pwidth * prem + "px !important"
      html.setAttribute('style', 'font-size:' + foneSize)
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
