<template>
  <div id="page-template" v-if="show">
    <!-- 页面模板 -->
    <div class="page_header">
      <img src="@/assets/img/logo@2x.png" @click="toHome" alt="logo">
      <a-button @click="toLogin" class="login-button" v-if="isPC">登录</a-button>
      <a-button @click="showModal = true" class="register-button">注册</a-button>
    </div>
    <div class="page_content"> 
      <slot />
    </div>
    <div class="page_footer">
      <div>联系客服: &nbsp; creator@kandaovr.com</div>
      <div><a href="https://www.kandaovr.com/" class="kandao_href">看到科技</a></div>
      <div>Copyright © 2017-2021 Kandao. All Rights Reserved. <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备16055699号</a></div>
    </div>
    <!-- 注册弹窗 -->
    <RegisterModal :show-modal="showModal" @cancelModal="showModal = false"></RegisterModal>
  </div>
</template>

<script>
import './index.min.css'
import RegisterModal from '@/components/RegisterModal'

export default {
  name: 'PageTemplate',
  props: {
    bgColor: {
      default: ''
    }
  },
  components: {
    RegisterModal
  },
  data: function () {
    return {
      show: false,
      showModal: false
    }
  },
  computed: {
    isPC: function () {
      return this.$store.state.isPC
    }
  },
  methods: {
    // 登录
    toLogin () {
      window.open(`https://creator.kandao360.com/users/#/login`, '_blank')
    },

    toKandao () {
      window.open(`https://www.kandaovr.com/`, '_blank')
    },
    toHome () {
      this.$router.push({ name: 'Home' })
    }
  },
  mounted() {
    var that = this;
    setTimeout(()=>{
      that.show = true
    }, 1000);

  }
}
</script>

<style scoped lang="scss">

</style>
