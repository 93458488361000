<template>
  <!-- 注册弹窗 -->
  <a-modal
    v-model="isRegister"
    :footer="null"
    :centered="true"
    :destroyOnClose="true"
    :maskClosable="false"
    width="50%"
    :bodyStyle="{ 'height': '520px', 'width': '100%' }"
    dialogClass="register-modal"
    @cancel="cancelModal"
  >
    <iframe :src="iframeSrc" width="100%" height="100%" ref="iframe" frameborder="0"></iframe>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'

export default {
  name: 'RegisterModal',
  props: {
    showModal: {
      default: false
    }
  },
  data: function () {
    return {
      iframeSrc: '',
      appID: process.env.VUE_APP_ID,
      iframeDomain: process.env.VUE_APP_IFRAME_DOMAIN,
    }
  },
  computed: {
    isPC: function () {
      return this.$store.state.isPC
    },

    isRegister: {
      get: function() {
        return this.$props.showModal
      },
      set: function (newVal) {
        return newVal
      }
    }
  },
  mounted() {
    var that = this;

    this.iframeSrc = `${this.iframeDomain}?appID=${this.appID}`;

    // 获取子页面传入的数据
    window.addEventListener('message', function(event) {
      // 注册成功
      if (event.data.uid) {
        message.success('注册成功');
        that.$emit('cancelModal')
      }
      console.log('获取子级B页面返回值:', event.data);
    })
  },
  methods: {
    // 登录
    cancelModal () {
      this.$emit('cancelModal')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
